// DTO là data fields sẻ GỬI và NHẬN từ server.
// Vì vậy phải map field đúng 100% so với phía server.
// DTO chia làm 2 loại:
// 1. Không kèm từ Response -> gửi lên server
// 2. kèm từ Response -> fields data từ server
// LƯU Ý: DTO chỉ dành cho service, ko dành cho Repository, UI,

export interface UsersDTO {
  displayName?: null;
  phone?: string;
  initialPassword?: string;
}

export interface UsersUpdateDTO {
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string;
  businessId?: string;
  isActive?: boolean;
  isBanned?: boolean;
  bannedCode?: null;
  displayName?: null;
  authorId?: string;
  role?: string;
  totalPoints?: number;
  lifetimePoints?: number;
  isFavorite?: null;
  allowNotification?: boolean;
  metadata?: null;
  user?: UserUpdateDTO;
}

export interface UserUpdateDTO {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  firstName?: null;
  lastName?: null;
  displayName?: string;
  username?: null;
  email?: null;
  emailVerified?: null;
  phone?: string;
  phoneVerified?: null;
  passwordHash?: string;
  avatarId?: null;
  isActive?: boolean;
  inActiveDate?: null;
  organizationId?: null;
  role?: string;
  totalPoints?: number;
  lifetimePoints?: number;
  slug?: null;
  globalNotification?: boolean;
  metadata?: null;
}
