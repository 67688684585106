import { Injectable } from '@angular/core';
import { createStore, withProps, select } from '@ngneat/elf';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { syncState } from 'elf-sync-state';
import { StickyQRApp } from './app.model';

const store = createStore({ name: 'app' }, withProps<StickyQRApp>({}));

export const persist = persistState(store, {
  key: 'app',
  storage: localStorageStrategy,
});

syncState(store);

@Injectable({ providedIn: 'root' })
export class AppRepository {
  get store() {
    return store;
  }

  constructor() {}

  businessTimezone$ = store.pipe(select((state) => state.business?.timeZone));

  update(app: StickyQRApp) {
    store.update((state) => ({ ...state, ...app }));
  }

  get role() {
    return this.store.getValue()?.business?.role;
  }
  get username() {
    return this.store.getValue()?.business?.username;
  }
  get currentBid() {
    return this.store.getValue()?.business?.id;
  }
  get currency() {
    return this.store.getValue()?.business?.currency;
  }
  get currencySym() {
    let cur = 'VND';
    if (this.store.getValue()?.business?.currency != cur) {
      return 'USD';
    }
    return cur;
  }

  get currentPlan() {
    return this.store.getValue()?.business?.currentPlan;
  }
  get accountName() {
    return this.store.getValue()?.business?.name;
  }
  get planInterval() {
    return this.store.getValue()?.business?.currentPlanInterval;
  }

  get business() {
    return this.store.getValue()?.business;
  }

  get businessFuture() {
    return this.store.getValue()?.businessFeature;
  }

  get businessTimezone() {
    return this.store.getValue()?.business?.timeZone;
  }

  get uid() {
    return this.store.getValue()?.business?.userId;
  }

  get phone() {
    return this.store.getValue()?.phone;
  }

  get email() {
    return this.store.getValue()?.email;
  }
}
