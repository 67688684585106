/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { API, APIService } from '@sticky/common';
import {
  setEntities,
} from '@ngneat/elf-entities';
import { Observable, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { NavigationPermissions } from './navigation-permissions.model';
import { NavigationPermissionsRepository } from './navigation-permissions.repository';
import { AppRepository } from '../app';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class NavigationPermissionsService {
  constructor(
    private readonly api: APIService,
    private repo: NavigationPermissionsRepository,
    private readonly app: AppRepository,
  ) {}

  getNavigationPermissions(params?: any): Observable<NavigationPermissions> {
    let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.NAVIGATION_PERMISSIONS}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          const dataMap = data?.map(item => ({
            ...item,
            id: new Date().getTime() + Math.random()
          }));
          this.repo.store.update(setEntities(dataMap));
          const businessFeature = dataMap;
          this.app.update({
            businessFeature: businessFeature,
          });
        })
      );
  }
}
