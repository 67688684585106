import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// navigation.service.ts
@Injectable({
    providedIn: 'root'
  })
  export class NavigationService {
    private navigationSource = new BehaviorSubject<string>('');
    public navigation$ = this.navigationSource.asObservable();
  
    setNavigation(from: string) {
      this.navigationSource.next(from);
    }
  }