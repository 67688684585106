import { CommonModule, NgClass, NgFor } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { LanguagesSupport, appRoutes } from '@sticky/config';
import { BehaviorSubject, Subject, Subscription, filter, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AppRepository } from '@sticky/state/app';
import { AuthService } from '@sticky/state/auth';
import { NavigationPermissionsRepository } from '@sticky/state/navigation-permissions/navigation-permissions.repository';
import { NavigationPermissions } from '@sticky/state/navigation-permissions/navigation-permissions.model';
import { MatDialog } from '@angular/material/dialog';
import { FeaturesIntroductionDialogComponent } from 'app/components/features-introduction-dialog/features-introduction-dialog.component';
import { MenuFeatures } from '@sticky/config/permission';
const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});
@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  styles: [
    `
      navigation{
        height: 100%;
        display: flex;
        width: 100%;

      }
      .navigation a {
        border-bottom: 2px solid #ffffff;
      }
      .navigation a.active-vc,
      .navigation a.active {
        border-bottom: 2px solid #000000;
      }
      @media screen and (max-width: 1280px) {
          .navigation a.active-vc,
          .navigation .active {
            background-color: #f5f5f5;
            border-bottom: none !important;
          }
        }
    `,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FuseDrawerComponent,
    MatButtonModule,
    NgFor,
    NgClass,
    MatTooltipModule,
    RouterModule,
    TranslocoModule,
    
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'navigation',
        loader,
      },
    },
  ],
})
export class NavigationComponent implements OnInit, OnDestroy, OnChanges {
  @Output() closeMenuChange = new EventEmitter();
  @Input() country: string
  @Input() allMenuFeatures: NavigationPermissions[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  pathName: string;
  isScreenSmall: boolean;
  private menuItemsSubject = new BehaviorSubject<[]>([]);
  /**
   * Constructor
   */
  urlVoucher: string = '';
  private routerSubscription: Subscription;
  isActive: boolean = false;
  // menuFeatures = Object.values(MenuFeatures);
  menuFeatures: NavigationPermissions[] = [];
  // allMenuFeatures: NavigationPermissions[] = [];
  locale: string;
  constructor(
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _appRepository: AppRepository,
    @Inject(AuthService) private _authService: AuthService,
    public repo: NavigationPermissionsRepository,
    private _changeDetectorRef: ChangeDetectorRef,
    private _translate: TranslocoService,
    public _dialog: MatDialog,
    
  ) {
    this.pathName = this._router.url;
    this.urlVoucher = this._router.url?.split('?')[0];
    if(this.urlVoucher){
      this.updateActiveState();
    }
    this._translate.langChanges$.subscribe(data =>{
      this.locale = data;
  })
  }

  ngOnInit(): void {
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('lg');
      });
      this.routerSubscription = this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.urlVoucher = event.urlAfterRedirects.split('?')[0];;
        this.updateActiveState();         
      });
      
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['allMenuFeatures']) {
      this.allMenuFeatures = changes['allMenuFeatures'].currentValue;
      const enumOrder = Object.values(MenuFeatures);
          this.menuFeatures = this.allMenuFeatures
          .filter((feature) => enumOrder.includes(feature.key as MenuFeatures))
          .sort(
            (a, b) =>
              enumOrder.indexOf(a.key as MenuFeatures) -
              enumOrder.indexOf(b.key as MenuFeatures)
          );
          this._changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  updateActiveState(): void {
    const targetUrl = this.vouchersCalendar.split('?')[0];
    const parentRoute = this.urlVoucher.split('/')[1];
    if (this.urlVoucher === targetUrl || `/${parentRoute}` === targetUrl){
      this.isActive = true;
    }else{
      this.isActive = false;
    }
  }

  get vouchersCalendar(): string {
    return `/${appRoutes.VOUCHERS}`;
  }

  get role(){
    return this._appRepository?.role
  }

  get dashboard(): string {
    return `/${appRoutes.DASHBOARD.HOME}`;
  }

  get activities(): string {
    return `/${appRoutes.ACTIVITIES}`;
  }

  get rewards(): string {
    return `/${appRoutes.REWARDS}`;
  }
  get customers(): string {
    return `/${appRoutes.CUSTOMERS}`;
  }
  get usersStaff(): string {
    return `/${appRoutes.USER_STAFF}`;
  }
  get announcements(): string {
    return `/${appRoutes.ANNOUNCEMENTS}`;
  }
  get settings(): string {
    return `/${appRoutes.SETTINGS}`;
  }

  get business(): string {
    return `/${appRoutes.BUSINESS}`;
  }

  get campaigns(): string {
    return `/${appRoutes.CAMPAIGNS}`;
  }

  get offers(): string {
    return `/${appRoutes.OFFERS}`;
  }

  get vouchers(): string {
    return `/${appRoutes.VOUCHERS}/${appRoutes.CALENDAR}`;
  }

  getTranTitle(key: string) {
    switch(key) {
        case 'ACTIVITIES':
        const activities = this._translate.translate(
          'navigation.ACTIVITIES'
        );
        return  activities
        case 'CAMPAIGNS':
        const campaigns = this._translate.translate(
          'navigation.CAMPAIGNS'
        );
        return  campaigns
        case 'VOUCHERS':
        const vouchers = this._translate.translate(
            'navigation.VOUCHERS'
          );
        return  vouchers
        case 'CUSTOMERS':
        const customers = this._translate.translate(
            'navigation.CUSTOMERS'
          );
        return  customers
        case 'REWARDS':
        const rewards = this._translate.translate(
            'navigation.REWARDS'
          );
        return  rewards
        case 'ANNOUNCEMENTS':
          const announcements = this._translate.translate(
              'navigation.ANNOUNCEMENTS'
            );
          return  announcements
        case 'USERS':
          const users = this._translate.translate(
              'navigation.USERS'
          );
          return  users
        case 'GENERAL_SETTINGS':
          const settings = this._translate.translate(
                'navigation.SETTINGS'
          );
          return  settings
      default:
        return '';
    }
  }

  routerLink(key: string) {
    switch(key) {
      case 'ACTIVITIES':
        return `/${appRoutes.ACTIVITIES}`;
      case 'CAMPAIGNS':
        return `/${appRoutes.CAMPAIGNS}`;
      case 'VOUCHERS':
        return `/${appRoutes.VOUCHERS}`;
      case 'REWARDS':
        return `/${appRoutes.REWARDS}`;
      case 'CUSTOMERS':
        return `/${appRoutes.CUSTOMERS}`;
      case 'ANNOUNCEMENTS':
        return `/${appRoutes.ANNOUNCEMENTS}`;
      case 'USERS':
        return `/${appRoutes.USER_STAFF}`;
      case 'GENERAL_SETTINGS':
        return `/${appRoutes.SETTINGS}`;
    default:
      return '';
    }
  }

  onClouseMenu() {
    this.closeMenuChange.emit();
  }
  signOut(): void {
    this._authService.logout();
    this._router.navigate([`/${appRoutes.GET_STARTED}`]);
  }

  onOpenFeaturesIntroduction(key: string): void {
    // Lọc chỉ những mục có key hợp lệ
    const filteredItems = this.allMenuFeatures.filter((feature) =>
      feature?.isProRequired
    );
    const prioritizedItem = filteredItems.find(
      (item) => item.key === key
    );
    const prioritizedModule = prioritizedItem?.module;
    const data = this.allMenuFeatures.filter(
      (feature) =>
        feature?.isProRequired
    )
    .sort((a, b) => {
      // Nếu `key` trùng với `prioritizeKey`, đưa item đó lên đầu
      if (a.module === prioritizedModule && b.module !== prioritizedModule) return -1;
      if (b.module === prioritizedModule && a.module !== prioritizedModule) return 1;

      if (a.key === key) return -1;
      if (b.key === key) return 1;

      // Nhóm các item có cùng module lại gần nhau
      if (a.module < b.module) return -1;
      if (a.module > b.module) return 1;

    });
    this.onClouseMenu();
    const dialogRef = this._dialog.open(FeaturesIntroductionDialogComponent, {
      panelClass: 'features-introduction-dialog',
      data: data
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this._changeDetectorRef.markForCheck();
    });
  }

}
