export enum AllFeatures {
    DASHBOARD = "DASHBOARD",
    ANNOUNCEMENTS = "ANNOUNCEMENTS",
    ACTIVITIES = "ACTIVITIES",
    USERS = "USERS",
    CUSTOMERS = "CUSTOMERS",
    GENERAL_SETTINGS = "GENERAL_SETTINGS",
    BUSINESS_PROFILE = "BUSINESS_PROFILE",
    VOUCHERS = "VOUCHERS",
    CAMPAIGNS = "CAMPAIGNS",
    REWARDS = "REWARDS",
    POINT_CLAIMS = "POINT_CLAIMS",
    REFERRAL_PROGRAM = "REFERRAL_PROGRAM",
    CALL_BUTTON_INTEGRATIONS = "CALL_BUTTON_INTEGRATIONS",
    CALL_BUTTON_INTEGRATIONS_HMC = "CALL_BUTTON_INTEGRATIONS_HMC",
    CALL_BUTTON_MANAGE_TABLES_SERVICES = "CALL_BUTTON_MANAGE_TABLES_SERVICES",
    CALL_BUTTON_SETTINGS = "CALL_BUTTON_SETTINGS",
    FEATURE_CUSTOMER_PAYMENT = "FEATURE_CUSTOMER_PAYMENT",
    FEATURE_HELP_ME_CHOOSE = "FEATURE_HELP_ME_CHOOSE",
    FEATURE_LABEL_TEMPLATE = "FEATURE_LABEL_TEMPLATE",
    FEATURE_PAYMENT_GATEWAYS = "FEATURE_PAYMENT_GATEWAYS",
    FEATURE_PRINTERS = "FEATURE_PRINTERS",
    FEATURE_SIGNAGE = "FEATURE_SIGNAGE",
    GIVEN_POINTS = "GIVEN_POINTS",
    DEDUCT_POINTS = "DEDUCT_POINTS",
    //
    VOUCHERS_ONE_TIME = "VOUCHERS_ONE_TIME",
    VOUCHERS_AUTOMATED = "VOUCHERS_AUTOMATED",
    VOUCHERS_MESSAGING = "VOUCHERS_MESSAGING",
    VOUCHERS_TARGET_OFFERS = "VOUCHERS_TARGET_OFFERS",
    VOUCHERS_PROMO_CALENDAR = "VOUCHERS_PROMO_CALENDAR",
    FEATURE_SERVICE_TOKENS = "FEATURE_SERVICE_TOKENS",
    VOUCHERS_AI_GENERATED = "VOUCHERS_AI_GENERATED",
    CALL_BUTTON_FEEDBACK = "CALL_BUTTON_FEEDBACK",
    STATISTICS = "STATISTICS",
    CALL_BUTTON_PRIORITY = "CALL_BUTTON_PRIORITY",
    INVOICES = "INVOICES",
    BILLING = "BILLING",
    ROLES_AND_PERMISSIONS = "ROLES_AND_PERMISSIONS",
    CALL_BUTTON_INTEGRATIONS_CHECKIN = "CALL_BUTTON_INTEGRATIONS_CHECKIN",
    CALL_BUTTON_COORDINATION = "CALL_BUTTON_COORDINATION",
    //
    VOID_TRANSACTIONS = "VOID_TRANSACTIONS",
    GIVE_VOUCHERS = "GIVE_VOUCHERS",
  }

  export enum MenuFeatures {
    DASHBOARD = "DASHBOARD",
    ACTIVITIES = "ACTIVITIES",
    CAMPAIGNS = "CAMPAIGNS",
    VOUCHERS = "VOUCHERS",
    REWARDS = "REWARDS",
    CUSTOMERS = "CUSTOMERS",
    ANNOUNCEMENTS = "ANNOUNCEMENTS",
    USERS = "USERS",
    GENERAL_SETTINGS = "GENERAL_SETTINGS",
}