/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { UsersActivitiesRepository } from './users-activities.repository';
import { API, APIService } from '@sticky/common';
import {
  addEntities,
  setEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class UsersActivitiesService {
  constructor(
    private readonly api: APIService,
    private repo: UsersActivitiesRepository
  ) {}

  getUserActivities(id?: string, params?: any) {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api.get<any>(`${API.USERS_ACTIVITIES}/${id}/activities`, {
      params: pa,
      }).pipe(
      tap((res) => {
        if (res) {
          if (params?.page !== 0) {
            this.repo.store.update(addEntities(res.data));
          } else {
            this.repo.store.update(setEntities(res.data));
          }
        }
      })
    );
  }

  
}
