<div class="flex flex-col w-full h-full bg-card rounded-[16px]">
  <div class="flex justify-center">
    <div class="w-full h-[150px] mt-[-32px] md:mt-[-24px] bg-no-repeat bg-contain bg-center"[style.background-image]='"url(\"../assets/icons/bo-upgrade-bg.svg\")"'></div>
    <button class="w-[32px] h-[32px] absolute right-4 top-4" (click)="onClouse()" type="button">
      <mat-icon class="w-[32px] h-[32px]" [svgIcon]="'icon-close-feature'"></mat-icon>
    </button>
  </div>
  <div class="flex flex-col w-full h-full p-8 pt-0 mt-[-48px] min-h-[440px]">
    <ng-container *ngIf="isCarouselReady">
      <div class="flex flex-col w-full h-full ">
        <ng-container *ngIf="dataFeatures?.length > 0">
          <owl-carousel-o class="mt-4 pb-6" [options]="customOptions" (changed)="onChanged($event)">
            <ng-template *ngFor="let item of dataFeatures" carouselSlide>
              <div class="mb-[2px] flex items-center gap-1 text-nowrap text-[14px] font-medium uppercase leading-4">
                <div class="module-pro">Pro</div>
                <div class="">{{getTranTitle(item?.module)}}</div>
              </div>
             <div class="text-[18px] font-medium leading-6 mt-2">{{item?.title}}</div>
             <div class="offer-content text-[14px] mt-6">
              <markdown class="variable-binding markdown-description break-words" [data]="item?.description"></markdown></div>
           </ng-template>
         </owl-carousel-o>
        </ng-container>
       <div class="counter w-full flex justify-center items-center font-medium text-[12px]">
        {{currentSlide}}/{{dataFeatures?.length}}
      </div>
       <div class="flex flex-col mt-6">
        <div class="flex justify-center">
          <button (click)="onUpgrade()" class="flex justify-center items-center min-h-[40px] w-full bg-[#ff4f0f] hover:bg-[#f54100] text-[#ffffff] text-[14px] font-medium py-2 rounded-[8px]">{{ 'featuresIntroduction.UPGRADE' | transloco }}</button>
       </div>
      </div>
      </div>
    </ng-container>
    
</div>