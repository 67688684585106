
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppRepository } from '@sticky/state/app';
import { Observable } from 'rxjs';

interface FeaturePermission {
  key: string;
  isEnabled: boolean;
  module: string;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  get features() {
    return this.appRepo?.businessFuture
  }
  constructor(private router: Router,
    private appRepo: AppRepository,
  ) {
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const requiredKey = route.data['featureKey'];
    const feature = this.features.find(f => f.key === requiredKey);
    if (!feature) {
      this.router.navigate(['/access-denied']);
      return false;
    }

    if (!feature.isEnabled) {
      this.router.navigate(['/access-denied']);
      return false;
    }

    return true;
  }
}

// Tạo service để quản lý permissions
@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    private appRepo: AppRepository,
  ) {
    
  }
  get features() {
    return this.appRepo?.businessFuture
  }

  isFeatureEnabled(key: string): boolean {
    if(!this.features) {
      return false;
    }
    const feature = this.features.find(f => f.key === key);
    return feature ? feature.isEnabled : false;
  }
}