import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  getAllEntities,
  getEntity,
  selectAllEntitiesApply,
  withEntities,
} from '@ngneat/elf-entities';
import { NavigationPermissions } from './navigation-permissions.model';

const store = createStore(
  { name: 'navigation-permissions' },
  withEntities<NavigationPermissions>()
);

@Injectable({ providedIn: 'root' })
export class NavigationPermissionsRepository {
  constructor() {}

  navigationPermissions$ = store.pipe(selectAllEntitiesApply({}));

  update(navigationPermissions: NavigationPermissions) {
    store.update((state) => ({ ...state, ...navigationPermissions }));
  }

  annoucements() {
    return this.store.query(getAllEntities());
  }

  getAnnoucement(id: string) {
    return this.store.query(getEntity(id));
  }

  get store() {
    return store;
  }
}
