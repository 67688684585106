<div class="campaigns absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <div class="flex-auto h-full bg-card dark:bg-transparent overflow-auto">
    <div class="flex justify-center items-center w-full h-full">
      <div class="flex flex-col h-[426px] w-full max-w-[1104px] items-center justify-center px-4 bg-[url('/assets/images/empty-bg.svg')]">
        <div class="w-[150px] h-[150px] bg-no-repeat bg-contain bg-center" style="background-image: url('/assets/images/access-denied-empty-state.svg')"></div>
        <div class="text-[18px] font-medium mb-2 mt-4">{{ 'accessDenied.ACCESS_DENIED' | transloco }}</div>
        <p class="text-center">{{ 'accessDenied.YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE' | transloco }}</p>
        <div class="flex justify-center mt-6">
          <button (click)="onUpgrade()" class="flex justify-center items-center min-h-[40px] w-full bg-[#ff4f0f] hover:bg-[#f54100] text-[#ffffff] text-[14px] font-medium py-2 px-6 rounded-[8px]">{{ 'accessDenied.UPGRADE' | transloco }}</button>
       </div>
      </div>
    </div>
    
  </div>
</div>