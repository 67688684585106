import { CommonModule, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Subject } from 'rxjs';
import {
  TRANSLOCO_SCOPE,
  TranslocoModule,
} from '@ngneat/transloco';
import { appRoutes, LanguagesSupport } from '@sticky/config';
import { PipesModule } from '@sticky/util-common/pipes.module';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  UsersActivitiesRepository,
} from '@sticky/state/users-activities';
import { AppRepository } from '@sticky/state/app';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { enUS } from 'date-fns/locale';
import { Logger } from '@sticky/services';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { MarkdownModule, MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { NavigationService } from '@sticky/services/navigation.service';
const log = new Logger('ProcessUpdateComponent')


const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});
@Component({
  selector: 'features-introduction-dialog',
  templateUrl: './features-introduction-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    RouterLink,
    MatIconModule,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    NgClass,
    TranslocoModule,
    DatePipe,
    PipesModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    CarouselModule,
    MarkdownModule
  ],
  styles:[`
    .apply-type-dialog {
      max-width: 500px !important;
      width: calc(100% - 32px);
      .mdc-label{
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
      }
      .mdc-form-field{
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        padding-left: 56px;
      }
    } 
    @media (max-width: 767px) {
      .apply-type-dialog {
        max-width: 100% !important;
        
      }
    }
  `],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'featuresIntroduction',
        loader,
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
    {
      provide: MARKED_OPTIONS,
      useValue: {
        breaks: true // Đặt breaks thành true để xử lý các dòng mới trong Markdown
      }
    },
    provideMarkdown(),
  ],
})
export class FeaturesIntroductionDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,  // Bật touchDrag cho mobile
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
    },
    nav: true,
    margin: 0,
    items: 1,
    stagePadding: 0,
}
  dataFeatures;
  currentSlide = 1;
  isCarouselReady = false;
  constructor(
    public _appRepo: AppRepository,
    public repo: UsersActivitiesRepository,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialogRef: MatDialogRef<FeaturesIntroductionDialogComponent>,
    public _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {
    this.dataFeatures = data;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.isCarouselReady = true;
      this._changeDetectorRef.markForCheck();
    }, 300);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getTranTitle(key: string) {
    switch (key) {
      case "General":
        return "General";
      case "StickyPoints":
        return "Sticky Points";
      case "StickyVouchers":
        return "Sticky Vouchers";
      case "AdditionalFeatures":
        return "Additional Features";
      case "CallButton":
        return "Sticky Call Button";
      default:
        return "";
    }
  }

  onChanged(event: any) {
    this.currentSlide = event?.startPosition + 1;
  }

  onClouse(){
    this._dialogRef.close()
  }

  onUpgrade(){
      this._dialogRef.close('UPGRADE')
      this.navigationService.setNavigation('UPGRADE');
      this._router.navigate([`/${appRoutes.SETTINGS}/billing`], {
        relativeTo: this._activatedRoute,
      });
      
  }
  
}
