import { Route } from '@angular/router';
import { AppRoutes } from '@sticky/config';
import { AuthGuard } from '@sticky/state/auth/auth.guard';
import { NoAuthGuard } from '@sticky/state/auth/auth.no-guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { OffersRedirectGuard } from './modules/admin/offers/offers-redirect.guard';
import { PermissionGuard } from '@sticky/services/ permission-guard.service';
import { AccessDeniedComponent } from './modules/admin/access-denied/access-denied.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboard'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed-in user to the '/dashboard'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: `${AppRoutes.getStarted}`,
        loadChildren: () =>
          import('app/modules/auth/get-started/get-started.routes'),
      },
      {
        path: `${AppRoutes.business_support}`,
        loadChildren: () => import('app/modules/auth/sa-login/sa-login.routes'),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    // resolve: {
    //     initialData: initialDataResolver,
    // },
    children: [
      {
        path: `${AppRoutes.dashboard}`,
        loadChildren: () =>
          import('app/modules/admin/dashboard/dashboard.routes'),
      },
      {
        path: `${AppRoutes.dashboardSa}`,
        loadChildren: () =>
          import('app/modules/admin/dashboard-sa/dashboard-sa.routes'),
      },
      {
        path: `${AppRoutes.activities}`,
        canActivate: [PermissionGuard],
        data: { featureKey: 'ACTIVITIES' },
        loadChildren: () =>
          import('app/modules/admin/activities/activities.routes'),
      },
      {
        path: `${AppRoutes.usersStaff}`,
        canActivate: [PermissionGuard],
        data: { featureKey: 'USERS' },
        loadChildren: () =>
          import('app/modules/admin/users-staff/users-staff.routes'),
      },
      {
        path: `${AppRoutes.announcements}`,
        canActivate: [PermissionGuard],
        data: { featureKey: 'ANNOUNCEMENTS' },
        loadChildren: () =>
          import('app/modules/admin/announcements/announcements.routes'),
      },
      {
        path: `${AppRoutes.customers}`,
        canActivate: [PermissionGuard],
        data: { featureKey: 'CUSTOMERS' },
        loadChildren: () =>
          import('app/modules/admin/customers/customers.routes'),
      },
      {
        path: `${AppRoutes.rewards}`,
        canActivate: [PermissionGuard],
        data: { featureKey: 'REWARDS' },
        loadChildren: () =>
          import(
            'app/modules/admin/management-rewards/management-rewards.routes'
          ),
      },
      {
        path: `${AppRoutes.settings}`,
        loadChildren: () =>
          import('app/modules/admin/settings-general/settings-general.routes'),
      },
      {
        path: `${AppRoutes.business}`,
        loadChildren: () =>
          import('app/modules/admin/business/business.routes'),
      },
      {
        path: `${AppRoutes.campaigns}`,
        canActivate: [PermissionGuard],
        data: { featureKey: 'CAMPAIGNS' },
        loadChildren: () =>
          import(
            'app/modules/admin/campaigns/campaigns.routes'
          ),
      },
      {
        path: `${AppRoutes.offers}`,
        canActivate: [OffersRedirectGuard],
        loadChildren: () =>
          import(
            'app/modules/admin/offers/offers.routes'
          ),
      },
      {
        path: `${AppRoutes.serviceTokens}`,
        loadChildren: () =>
          import(
            'app/modules/admin/service-tokens/service-tokens.routes'
          ),
      },
      {
        path: `${AppRoutes.callback}`,
        loadChildren: () =>
          import(
            'app/modules/admin/settings-general/callback-payment/callback-payment.routes'
          ),
      },
      {
        path: `${AppRoutes.vouchers}`,
        loadChildren: () =>
          import(
            'app/modules/admin/vouchers/vouchers.routes'
          ),
      },
      {
        path: `${AppRoutes.accessDenied}`,
        component: AccessDeniedComponent
      }
      // {
      //   path: `${AppRoutes.approve}`,
      //   loadChildren: () =>
      //     import(
      //       'app/modules/admin/approve/approve.routes'
      //     ),
      // },
    ],
  },
];
