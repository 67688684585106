import {
  AsyncPipe,
  I18nPluralPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Subject } from 'rxjs';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { appRoutes, LanguagesSupport } from '@sticky/config';
import { PipesModule } from '@sticky/util-common/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule, } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';

const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});
@Component({
  selector: 'access-denied',
  templateUrl: './access-denied.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: [
    `
      
    `,
  ],
  imports: [
    MatSidenavModule,
    RouterOutlet,
    NgIf,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgFor,
    TranslocoModule,
    NgClass,
    RouterLink,
    MatMenuModule,
    AsyncPipe,
    PipesModule,
    I18nPluralPipe,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'accessDenied',
        loader,
      },
    },
  ],
})
export class AccessDeniedComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onUpgrade(){
    this._router.navigate([`/${appRoutes.SETTINGS}/billing`], {
      relativeTo: this._activatedRoute,
    });
}
  
}

