// Models sẻ sử dụng cho Repository, UI
// KHÔNG sử dụng cho Service


export interface UsersActivities {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string;
  businessId?: string;
  points?: number;
  type?: string;
  qrId?: null;
  deletedAt?: null;
  usersOnBusinessesUserId?: null;
  usersOnBusinessesBusinessId?: null;
  usersOnBusinessesRole?: null;
  authorId?: null;
  metadata?: null;
  qr?: null;
  rewardsOnTransaction?: RewardsOnTransaction;
  user?: User;
}

export interface User {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  firstName?: null;
  lastName?: null;
  displayName?: string;
  username?: null;
  email?: null;
  emailVerified?: null;
  phone?: string;
  phoneVerified?: null;
  passwordHash?: string;
  avatarId?: null;
  isActive?: boolean;
  inActiveDate?: null;
  organizationId?: null;
  role?: string;
  totalPoints?: number;
  lifetimePoints?: number;
  slug?: null;
  globalNotification?: boolean;
  metadata?: null;
}

export interface RewardsOnTransaction {
  createdAt?: Date;
  updatedAt?: Date;
  transactionId?: string;
  rewardName?: string;
  cost?: number;
  authorId?: string;
  rewardId?: string;
}

export interface UsersActivitiesMeta{
  filters?: UsersActivitiesFilter;
}

export interface UsersActivitiesFilter{
  text?: string;
  displayName?: string;
}
