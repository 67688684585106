import { el } from 'date-fns/locale';
import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntitiesApply, withEntities } from '@ngneat/elf-entities';
import { combineLatest, map, switchMap } from 'rxjs';
import {
  UsersActivities,
  UsersActivitiesFilter,
  UsersActivitiesMeta,
} from './users-activities.model';
import { startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import _ from 'lodash-es';
import { AppRepository } from '../app';
const store = createStore(
  { name: 'users-activities' },
  withProps<UsersActivitiesMeta>({}),
  withEntities<UsersActivities>()
);
@Injectable({ providedIn: 'root' })
export class UsersActivitiesRepository {
  constructor(public appRepo: AppRepository,) {}
  get business() {
    return this.appRepo?.business;
  }
  announcements$ = store.pipe(selectAllEntitiesApply({}));
  userActivitiesFilter$ = store.pipe(select((state) => state.filters));
  userActivities$ = combineLatest([this.userActivitiesFilter$]).pipe(
    switchMap(([filter]) => {
      return store.pipe(selectAllEntitiesApply({}));
    })
  );

  get activitiesGroup$() {
    return store.pipe(selectAllEntitiesApply({})).pipe(
      map((data) => {
        return _(data)
          .groupBy((x) => new Date(startOfDay(new Date(utcToZonedTime(x?.createdAt, this.business?.timeZone)))))
          .map((value, key) => ({
            date: key,
            data: value,
          }))
          .value();
      })
    );
  }

  update(usersActivities: UsersActivities) {
    store.update((state) => ({ ...state, ...usersActivities }));
  }

  get store() {
    return store;
  }

  getFilter() {
    return this.store.getValue().filters;
  }

  filterEntity(entity: UsersActivities, filter?: UsersActivitiesFilter) {
    if (!filter) return true;
    if (filter.text && filter.text != '') {
      if (
        entity?.user.displayName
          ?.toLowerCase()
          .indexOf(filter.text.toLowerCase()) === -1 &&
        entity?.user.phone?.toLowerCase().indexOf(filter.text.toLowerCase()) ===
          -1
      ) {
        return false;
      }
    }
    return true;
  }

  setFilter(filter?: UsersActivitiesFilter) {
    let filters = filter;
    if (filter) {
      filters = { ...this.getFilter(), ...filter };
    }
    store.update((state) => ({
      ...state,
      filters: filters,
    }));
  }
}
